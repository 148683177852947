<template>
  <v-container fluid>
    <AError :api="apiGetXeroToken" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-card>
      <v-toolbar :color="this.hrdcData.HRDC_BLUE_THEME_COLOR">
        <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.name.xero_token }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span v-if="hrdcData.isHrdcSuperAdminRoles">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="white"
                class="hidden-xs-only"
                @click="generateXeroToken()"
              >
                <v-icon dark v-bind="attrs" v-on="on"> mdi-shield-plus </v-icon>
              </v-btn>
            </template>
            <span>Generate XERO Token</span>
          </v-tooltip>
        </span>
      </v-toolbar>

      <div>
        <div class="pa-5">
          <v-data-table
            :headers="headers"
            :items="xeroDatas"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:item.err="{ item }">
              <div v-html="item.err"></div>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetXeroToken: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    errorField: {
      applicationNo: null,
    },
    loadingDialog: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "index",
      },
      { text: "Valid From", value: "validFrom", sortable: false },
      { text: "Valid To", value: "validTo", sortable: false },
      { text: "Generate Date", value: "timestampCreated", sortable: false },
    ],
    xeroDatas: [],
  }),
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetXeroToken: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/xero/get-xero-token-setting`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    this.hrdcData.rolesAssignation(this.auth.roles);
    this.xeroDatas = [];

    this.apiGetXeroToken.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;

        if (!status) {
          this.$store.dispatch("showMessage", {
            message: data,
            messageType: "error",
            timeout: 2000,
          });

          return;
        }

        if (status) {
          if (data) {
            let index = 1;
            for (const item of data) {
              const { id, validFrom, validTo, timestampCreated } = item;

              this.xeroDatas.push({
                id,
                index,
                timestampCreated: moment(timestampCreated).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                validFrom: moment(validFrom).format("YYYY-MM-DD"),
                validTo: moment(validTo).format("YYYY-MM-DD"),
              });
              index++;
            }
          }
        }
        this.hideLoadingDialog();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetXeroToken.fetch();

    this.hideLoadingDialog();
  },
  methods: {
    redirectBack() {
      let route = "HrdcEinvoicingSettings";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    generateXeroToken() {
      this.showLoadingDialog();
      const redirectCallbackUri = `${process.env.VUE_APP_URL}/en/service/hrdc_einvoicing/callback_xero_einvoicing`;
      const xeroParams = {
        redirectCallbackUri,
        stateType: "token",
      };
      const childWindow = window.open(this.requestAuthorizeApp(xeroParams));
      var timer = setInterval(() => {
        if (childWindow.closed) {
          clearInterval(timer);
        }
      }, 500);
      this.hideLoadingDialog();
    },
  },
};
</script>
